import React, { Component } from "react"
import { pure, compose } from "recompose"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import classNames from "classnames"
import { Link } from "gatsby"

import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import { scrollToTop } from "../utils"

type Props = {
  classes: any
}

class Footer extends Component<Props> {
  props: Props

  render() {
    const { classes } = this.props

    return (
      <footer className={classes.root}>
        <div className={classes.footer}>
          <Button
            fullWidth={true}
            className={classes.scrollToTop}
            onClick={() => scrollToTop(400)}
          >
            <KeyboardArrowUp />
            &nbsp;TOP
          </Button>
        </div>
        <div className={classNames(classes.footer, "pt10")}>
          <div>
            <Link to="/contact/">お問い合わせ</Link>
            <span className="mr10 ml10">|</span>
            <Link to="/privacy/">プライバシーポリシー</Link>
          </div>
          <div className={classes.copyright}>
            Copyright &copy; 一般社団法人なでしこケア All Rights Reserved.
          </div>
        </div>
      </footer>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    fontFamily: "Noto Sans JP",
  },
  footer: {
    backgroundColor: "#fff",
    color: "#333",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontSize: 12,
  },
  copyright: {
    paddingTop: 10,
    paddingBottom: 10,
    position: "relative",
    textAlign: "center",
  },
  scrollToTop: {
    color: "#333",
  },
  flex: {
    display: "flex",
  },
  flexColumn: {
    flexDirection: "column",
  },
  flexEnd: {
    alignItems: "flex-end",
  },
})

export default compose(
  pure,
  withStyles(styles)
)(Footer)
