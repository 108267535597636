/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import WebFont from "webfontloader"

import Header from "./Header"
import Footer from "./Footer"
import Animation from "./Animation"

import "./layout.css"
import "../styles//custom.scss"
import "../styles/adjustiment.scss"
import "../styles/animation.scss"

class Layout extends Component {
  componentDidMount() {
    WebFont.load({
      google: {
        families: ["Advent+Pro", "Noto+Sans+JP", "Roboto", "Sawarabi+Mincho"],
      },
    })
  }

  render() {
    const { classes, children } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <Animation>
            <div className={classes.root}>
              <Header />
              <main>{children}</main>
              <Footer />
            </div>
          </Animation>
        )}
      />
    )
  }
}

const styles = theme => ({
  root: {
    backgroundSize: "100%",
    position: "relative",
    minHeight: "100vh",
    paddingBottom: 121,
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 112,
    },
  },
})

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withStyles(styles)(Layout)
