import { Link } from "gatsby"

import React, { Component } from "react"
import { pure, compose } from "recompose"
import { withStyles } from "@material-ui/core/styles"
import withWidth from "@material-ui/core/withWidth"
import { scrollToTarget } from "../utils"
import classNames from "classnames"

import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/icons/Menu"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import MailIcon from "@material-ui/icons/MailOutline"
import Box from "@material-ui/core/Box"
import { Typography } from "@material-ui/core"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"

type Props = {
  classes: any
  width: string
}

type State = {
  openDrawer: boolean
  menuItems: Array<MenuItem>
}

type MenuItem = {
  name: JSX.Element
  link: string
  target?: string
}

class Header extends Component<Props, State> {
  constructor(props: any) {
    super(props)
    const { classes } = props

    const menuItems = [
      {
        name: <span>about us</span>,
        link: "about-us",
      },
      { name: <span>message</span>, link: "message" },
      { name: <span>news</span>, link: "news" },
      {
        name: <span>note</span>,
        link: "https://note.com/nadecare78",
        target: "external",
      },
      {
        name: <span>what we do</span>,
        link: "what-we-do",
      },
      { name: <span>our stories</span>, link: "our-stories" },
      {
        name: <span>organization</span>,
        link: "organization",
      },
    ]

    this.state = {
      openDrawer: false,
      menuItems,
    }
  }

  _toggleDrawer = (open: boolean) => () => {
    this.setState({
      openDrawer: open,
    })
  }

  _handleOnClick = (menuItem: MenuItem) => (event: any) => {
    if (menuItem.target === "external") {
      document.location.href = menuItem.link
    } else {
      if (document.location.pathname === "/") {
        scrollToTarget(menuItem.link)
      } else {
        document.location.href = `/#${menuItem.link}`
      }
    }
  }

  render() {
    const { classes } = this.props

    const menuListDrawer = (
      <List>
        <div className={classes.drawerTitleWrapper}>
          <div className={classes.title}>
            <div className={classes.titleText}>
              なで
              <br />
              ケア
            </div>
          </div>
        </div>
        <Divider />
        {this.state.menuItems.map((menuItem, i) => (
          <ListItem
            button
            key={`drawer-menu-${i}`}
            component={Link}
            to={
              menuItem.target === "external"
                ? menuItem.link
                : `#${menuItem.link}`
            }
          >
            <ListItemText
              primary={menuItem.name}
              className={classes.menuLink}
            />
          </ListItem>
        ))}
      </List>
    )

    const menuList = (
      <>
        {this.state.menuItems.map((menuItem, i) => (
          <div className={classes.rightItem} key={`list-menu-${i}`}>
            <div
              onClick={this._handleOnClick(menuItem)}
              className={classes.menuLink}
            >
              <div className={classes.rightLink}>
                <span>{menuItem.name}</span>
              </div>
              <div className={classes.menuBorder} />
            </div>
          </div>
        ))}
      </>
    )

    return (
      <div>
        <Box p={2} bgcolor="#1D2F50">
          <Link
            to="https://www.spportunity.com/tokyo/team/754/invest/591/detail"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "#FF4E5E",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography style={{ color: "#FF4E5E", marginRight: 8 }}>
              クラウドファウンディングはこちらから
            </Typography>
            <OpenInNewIcon style={{ color: "#FF4E5E" }} />
          </Link>
        </Box>
        <div className={classes.root}>
          <div className={classes.box} />
          <div
            className={classNames(classes.componentWrapper, classes.container)}
          >
            <div className={classes.left}>
              <Link to={"/"} className={classes.menuLink}>
                <div className={classes.title}>
                  <div className={classes.titleText}>
                    なで
                    <br />
                    ケア
                  </div>
                </div>
              </Link>
            </div>
            <div className={classes.right}>
              <div className={classes.contactUs}>
                <a
                  className={classes.contactUsIcon}
                  href="https://twitter.com/nadecare"
                >
                  <img
                    style={{ margin: 0, width: 40 }}
                    src="https://img.icons8.com/color/48/000000/twitter-squared.png"
                    alt="Twitter"
                  />
                </a>
                <a
                  className={classes.contactUsIcon}
                  href="https://www.instagram.com/nadecare.jp"
                >
                  <img
                    className="mr6"
                    style={{ margin: 0, width: 40 }}
                    src="https://img.icons8.com/color/48/000000/instagram.png"
                    alt="Instagram"
                  />
                </a>
                <Link to="/contact/" className={classes.contactUsIcon}>
                  <MailIcon fontSize="large" className="mr10" />{" "}
                  <div className={classes.contactUsText}>CONTACT US!! </div>
                </Link>
                <div className={classes.harassment}>
                  <Link
                    to="/assistant-service/"
                    className={classes.harassmentLink}
                  >
                    <div className={classes.dotFlex}>
                      <div className={classes.dot}></div>
                      <div className={classes.dot}></div>
                    </div>
                    <div className={classes.p5}>
                      <div>オンライン</div>
                      <div>お悩み相談室</div>
                    </div>
                    <div className={classes.dotFlex}>
                      <div className={classes.dot}></div>
                      <div className={classes.dot}></div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className={classes.iconButtonRoot}>
                <IconButton
                  aria-haspopup="true"
                  onClick={this._toggleDrawer(true)}
                  className={classes.button}
                >
                  <Menu />
                </IconButton>
              </div>
              <>{menuList}</>
            </div>
            <Drawer
              open={this.state.openDrawer}
              onClose={this._toggleDrawer(false)}
              anchor="top"
            >
              <div
                tabIndex={0}
                role="button"
                onClick={this._toggleDrawer(false)}
                onKeyDown={this._toggleDrawer(false)}
              >
                {menuListDrawer}
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    height: 100,
    marginBottom: 80,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 50,
    },
  },
  box: {
    width: "100%",
    height: 500,
    top: -500,
    backgroundColor: "#ff4e5e",
    position: "absolute",
    zIndex: -1,
    transform: "skew(0deg, -10deg)",
    [theme.breakpoints.down("sm")]: {
      top: -470,
    },
    [theme.breakpoints.down("sm")]: {
      top: -460,
    },
  },
  componentWrapper: {
    maxWidth: 1280,
    margin: "auto",
  },
  container: {
    display: "flex",
  },
  left: {
    //width: 300,
    marginTop: 21,
    marginLeft: 24,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 8,
    },
  },
  iconButtonRoot: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  title: {
    width: 140,
    height: 140,
    borderRadius: "50%",
    border: "solid 6px #1e365c",
    background: "white",
    textAlign: "center",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 100,
      border: "solid 4px #1e365c",
    },
    [theme.breakpoints.down("xs")]: {
      width: 100,
      height: 100,
      border: "solid 4px #1e365c",
    },
  },
  titleText: {
    color: "#ff4e5e",
    fontSize: 35,
    letterSpacing: 6,
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontStyle: "normal",
    fontStretch: "normal",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    lineHeight: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      letterSpacing: 4,
      lineHeight: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
      letterSpacing: 4,
      lineHeight: "35px",
      //fontWeight: 'bold',
    },
  },
  right: {
    marginLeft: "auto",
    marginRight: 65,
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    //paddingTop: 110,
    paddingBottom: 10,
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
      paddingTop: 80,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 8,
      paddingTop: 80,
    },
  },
  rightItem: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rightLink: {
    height: 24,
    fontFamily:
      '"Advent Pro", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 22,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.2,
    letterSpacing: 0.7,
    textAlign: "center",
    color: "#1e365c",
    textTransform: "uppercase",
    display: "inline-block",
  },
  button: {
    textTransform: "none",
  },
  drawerTitleWrapper: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 8,
  },
  menuLink: {
    textDecoration: "none",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  menuBorder: {
    height: 4,
    marginTop: 6,
    marginRight: 6,
    marginLeft: 6,
    backgroundColor: "#1e365c",
  },
  contactUs: {
    position: "absolute",
    top: 20,
    color: "#1e365c",
    display: "flex",
    alignItems: "center",
  },
  contactUsIcon: {
    display: "flex",
    alignItems: "center",
    fontFamily:
      '"Roboto", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 20,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    color: "#1e365c",
    paddingLeft: 6,
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  contactUsText: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  harassment: {
    backgroundColor: "#ffff6b",
    backgroundSize: "29px 70px",
    textAlign: "center",
    marginLeft: 20,
    borderStyle: "none",
    padding: "7px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
  },
  harassmentLink: {
    textDecoration: "none",
    lineHeight: 1.2,
    fontFamily:
      '"Roboto", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 14,
    fontWeight: "bold",
    marginTop: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  dotFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    background: "gray",
  },
  p5: {
    padding: 5,
  },
})

export default compose(
  pure,
  withWidth(),
  withStyles(styles)
)(Header)
