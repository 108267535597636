import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withAssetPrefix } from "gatsby"

const backgroundImage = "/assets/images/top.jpg"

type Props = {
  classes: any
}

type State = {
  positions: Array<Element>
}

class Animation extends Component<Props, State> {
  async componentDidMount() {
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "animation"
    )
    const positions: Array<Element> = Array.from(elements)
    window.addEventListener(
      "scroll",
      event => this.trackScrolling(positions),
      true
    )

    this.setState({
      positions,
    })
  }

  componentWillUnmount() {
    window.removeEventListener(
      "scroll",
      event => this.trackScrolling(this.state.positions),
      true
    )
  }

  trackScrolling(positions: Array<Element>) {
    const html = document.documentElement
    const body = document.body

    // ページ全体の高さ
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    // ウインドウの高さ
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight
    // ウインドウの底
    const windowBottom = windowHeight + window.pageYOffset

    positions.map((elem, i) => {
      const elemPos = elem.getBoundingClientRect().top
      const currentPos = window.scrollY
      if (
        elemPos + currentPos <= currentPos + windowHeight / 1.2 ||
        windowBottom >= docHeight
      ) {
        elem.classList.add("started")
        setTimeout(() => {
          elem.classList.add("ended")
        }, 500)
      }
    })
  }

  render() {
    const { classes } = this.props

    return <article className={classes.root}>{this.props.children}</article>
  }
}

const styles = (theme: any) => ({
  root: {},
})

export default withStyles(styles)(Animation)
